import { CollectionRole } from "./enums";

const COLLECTION_ROLE_ORDERING = [
  CollectionRole.MANAGER,
  CollectionRole.MEMBER,
];

export const ALL_COLLECTION_ROLES = Object.values(CollectionRole);

export const isCollectionRoleSuperset = (
  role: CollectionRole,
  targetRole: CollectionRole,
): boolean => {
  if (COLLECTION_ROLE_ORDERING.indexOf(role) === -1) {
    console.error(`Invalid role ${role}`);
    return false;
  }

  return (
    COLLECTION_ROLE_ORDERING.indexOf(role) <=
    COLLECTION_ROLE_ORDERING.indexOf(targetRole)
  );
};

export const hasCollectionRole = (
  roles: readonly CollectionRole[],
  targetRole: CollectionRole,
): boolean => {
  return roles.some((role) => isCollectionRoleSuperset(role, targetRole));
};

export const maxCollectionRole = (
  roles: readonly CollectionRole[],
): CollectionRole | undefined => {
  return sortedCollectionRoles(roles, "max_first")[0];
};

function sortedCollectionRoles(
  roles: readonly CollectionRole[],
  direction: "min_first" | "max_first",
): readonly CollectionRole[] {
  const directionMultiplier = direction === "max_first" ? 1 : -1;
  return [...roles].sort(
    (a, b) =>
      directionMultiplier *
      (COLLECTION_ROLE_ORDERING.indexOf(a) -
        COLLECTION_ROLE_ORDERING.indexOf(b)),
  );
}

export const humanReadableCollectionRole = (
  collectionRole: CollectionRole,
): string =>
  ({
    [CollectionRole.MANAGER]: "Manager",
    [CollectionRole.MEMBER]: "Member",
  })[collectionRole];

export const collectionRoleDescription = (
  collectionRole: CollectionRole,
): string =>
  ({
    [CollectionRole.MANAGER]:
      "Collection managers can edit collection settings, including adding and removing members and projects.",
    [CollectionRole.MEMBER]:
      "Collection members can access projects and components in the collection.",
  })[collectionRole];
