import {
  Dictionary,
  Literal,
  Optional,
  Array as RArray,
  Boolean as RBoolean,
  Record as RRecord,
  String as RString,
  Static,
  Union,
} from "runtypes";

import { FilledDynamicValueTableColumnTypeLiteral } from "../DynamicValue";
import { DisplayTableColumnId } from "../idTypeBrands";
import { ScopeItemType } from "../scopeTypes.js";

export const CalcColumnDefinition = RRecord({
  name: DisplayTableColumnId,
  expression: RString,
  // `muted` is no longer supported, it's just around for backwards compatibility
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- these are safe
  muted: Optional(RBoolean.nullable()),
});
export type CalcColumnDefinition = Static<typeof CalcColumnDefinition>;

export const CalcReferencedColumnTypes = Dictionary(
  FilledDynamicValueTableColumnTypeLiteral,
  DisplayTableColumnId,
);
export type CalcReferencedColumnTypes = Static<
  typeof CalcReferencedColumnTypes
>;

/** The set of ScopeItemTypes that calcs expressions can handle */
export const CalcScopeItemTypeSubset = Union(
  Literal("STRING"),
  Literal("NUMBER"),
  Literal("BOOLEAN"),
  Literal("DATE"),
  Literal("DATETIME"),
);
export type CalcScopeItemTypeSubset = Static<typeof CalcScopeItemTypeSubset>;
// make sure `CalcScopeItemTypeSubset` is actually a subset of `ScopeItemType`
const ___: ScopeItemType = null as unknown as CalcScopeItemTypeSubset;

export const CalcReferencedParamTypes = Dictionary(
  CalcScopeItemTypeSubset,
  RString,
);
export type CalcReferencedParamTypes = Static<typeof CalcReferencedParamTypes>;

export const CalcsDefinition = RRecord({
  calcColumns: RArray(CalcColumnDefinition),
  referencedColumnTypes: CalcReferencedColumnTypes,
  referencedParamTypes: Optional(CalcReferencedParamTypes.nullable()),
});
export type CalcsDefinition = Static<typeof CalcsDefinition>;

export const EMPTY_CALCS: CalcsDefinition = {
  calcColumns: [],
  referencedColumnTypes: {},
};
