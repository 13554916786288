export enum ReactiveParamType {
  VARIABLE = "VARIABLE",
  IMPORT = "IMPORT",
  FUNCTION = "FUNCTION",
  SECRET = "SECRET",
  AUTOMATIC_PARAM = "AUTOMATIC_PARAM",
  TABLE = "TABLE",
  QUERY_RESULT = "QUERY_RESULT",
  REMOTE_QUERY_RESULT = "REMOTE_QUERY_RESULT",
}

export const GLOBAL_REACTIVE_PARAM_TYPES = [
  ReactiveParamType.SECRET,
  ReactiveParamType.AUTOMATIC_PARAM,
];

export const SQL_QUERY_PARAM_TYPE = [
  ReactiveParamType.TABLE,
  ReactiveParamType.QUERY_RESULT,
  ReactiveParamType.REMOTE_QUERY_RESULT,
];
