import { Static, String } from "runtypes";

// to improve this further, we could build a runtype constraint
// that check that this is a string of an expected datetime format
export const DateTimeString = String.withBrand("DateTimeString");
export type DateTimeString = Static<typeof DateTimeString>;

export const getDateTimeString = (date: Date): DateTimeString =>
  date.toISOString() as DateTimeString;

// https://github.com/typeorm/typeorm/issues/2176
// typeorm returns date columns as strings. in-memory db unit tests return them as Date objects though. Most entities
// are also typed as Dates today so this method is intended to unify all this under DateTimeString
export const uniformDateTimeString = (date: Date | string): DateTimeString => {
  let dateObj: Date;

  if (typeof date === "object" && date instanceof Date) {
    dateObj = date;
  } else {
    const dateMs = Date.parse(date);
    if (dateMs <= 0) {
      throw new Error("Unable to parse date");
    }

    dateObj = new Date(dateMs);
  }

  return getDateTimeString(dateObj);
};

export const TimezoneName = String.withBrand("TimezoneName");
export type TimezoneName = Static<typeof TimezoneName>;
