import { FilledDynamicValueTableColumnType } from "../DynamicValue";
import { assertNever } from "../errors";

import { ChartDataType } from "./types";

export function dataFrameHeaderTypeToChartDataType(
  headerType: FilledDynamicValueTableColumnType,
): ChartDataType {
  switch (headerType) {
    case "STRING":
    case "UNKNOWN":
    case "BOOLEAN":
      return "string";
    case "DATE":
    case "DATETIME":
    case "DATETIMETZ":
    case "TIME":
      return "datetime";
    case "NUMBER":
      return "number";
    default:
      assertNever(headerType, headerType);
  }
}
