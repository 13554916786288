import {
  Array,
  Boolean,
  Dictionary,
  Intersect,
  Literal,
  Null,
  Number,
  Partial,
  Record,
  Static,
  String,
  Undefined,
  Union,
} from "runtypes";

import {
  FilledDynamicValueTableColumnType,
  FilledDynamicValueTableColumnTypeLiteral,
} from "../DynamicValue";
import { HqlAggregationFunctionLiteral } from "../hql/types.js";
import { DisplayTableColumnId } from "../idTypeBrands";
import { getNormalEnum } from "../runtypeEnums";

import { CalcColumnDefinition } from "./calcTypes.js";
import { ConditionalFormattingDefinitionWithMetadata } from "./conditionalFormattingTypes";

export const SortDirectionLiteral = Union(Literal("ASC"), Literal("DESC"));

export type SortDirection = Static<typeof SortDirectionLiteral>;
export const SortDirection = getNormalEnum(SortDirectionLiteral);
export type SortDirectionArray = ReadonlyArray<SortDirection | null> | null;

export const DisplayTableOutputColumnTypeLiteral =
  FilledDynamicValueTableColumnTypeLiteral;
export type DisplayTableColumnOutputType = Static<
  typeof FilledDynamicValueTableColumnTypeLiteral
>;
export const DisplayTableColumnOutputType = FilledDynamicValueTableColumnType;

export const DisplayTableOutputColumn = Record({
  // An identifier used to reference this column. Used for example in fields of
  // DisplayTableConfig to reference columns.
  columnId: DisplayTableColumnId,

  // The name of the column, which may be a list/tuple in the case of hierarchical
  // columns in a MultiIndex DataFrame. The length of the list will always match
  // the length of columnLevelNames on the containing DisplayTableOutput
  columnName: Array(String),

  columnType: DisplayTableOutputColumnTypeLiteral,
  columnData: Array(Union(String, Null, Undefined)),
});
export type DisplayTableOutputColumn = Static<typeof DisplayTableOutputColumn>;

const DisplayTableOutputRequiredFields = Record({
  rowCount: Number,

  columns: Array(DisplayTableOutputColumn),

  // a list of names for each level of the column index of the DataFrame
  // names can be null but the length of the array will always match the
  // length of each DisplayTableOutputColumn's columnName array
  columnLevelNames: Array(Union(String, Null)),

  indices: Union(
    Array(Union(String, Number, Array(Union(String, Number)))),
    Undefined,
  ),

  // a list of names for each level of the row index of the DataFrame
  indexLevelNames: Array(Union(String, Null)),

  // in the case that an update pageSize causes the current pageNumber
  // to be invalid, this lets the python indicate which page number
  // it chose to show instead
  pageNumberOverride: Union(Number, Null, Undefined),

  // These following fields _could_ be read/derived
  // from the display table cell and display table session state,
  // however, we mirror them here to make sure the rendered
  // output is always consistent. The reason being is these
  // fields feel very odd if updated before the table is recomputed
  pageSize: Number,

  // Please do not add any more fields to this record, all new fields must go in DisplayTableOutputOptionalFields
  // for backwards compatibility purposes. See https://www.notion.so/hexhq/Avoiding-breaking-changes-59f0a1d8f34546249eadb17605822290
  // form more context
});

const DisplayTableWarningTypeLiteral = Union(
  Literal("CONDITIONAL_FORMATTING_ERROR"),
);

export type DisplayTableWarningType = Static<
  typeof DisplayTableWarningTypeLiteral
>;
export const DisplayTableWarningType = getNormalEnum(
  DisplayTableWarningTypeLiteral,
);

const DisplayTableWarning = Record({
  type: DisplayTableWarningTypeLiteral,
  message: String,
});
export type DisplayTableWarning = Static<typeof DisplayTableWarning>;

export const ColumnAggregationsOutput = Dictionary(
  Record({
    result: Union(Number, String),
    func: HqlAggregationFunctionLiteral,
  }),
  DisplayTableColumnId,
);
export type ColumnAggregationsOutput = Static<typeof ColumnAggregationsOutput>;

export const DisplayTableExploreOutputType = Union(
  Literal("source"),
  Literal("pivot"),
  Literal("aggregated"),
);
export type DisplayTableExploreOutputType = Static<
  typeof DisplayTableExploreOutputType
>;

const DisplayTableOutputOptionalFields = Partial({
  calcColumns: Union(Array(CalcColumnDefinition), Null),
  // This field also has extra metadata that the BE adds in addition to copying
  // the conditionalFormatting definition from the display table cell.
  // Additionally, the arg field on ConditionalFormattingBinaryExpressions will
  // have been evaluated as Jinja templates in the output.
  conditionalFormatting: Union(
    ConditionalFormattingDefinitionWithMetadata,
    Null,
  ),

  configId: Union(String, Null),

  // size of the DF in bytes
  dfSize: Number,

  // if the display table was truncated, this will be larger than the rowCount
  rowCountBeforeTruncation: Number,

  // Whether the table is in query mode
  queryMode: Boolean,

  // Issues with the table that don't rise to the level of an error.
  warnings: Union(Array(DisplayTableWarning), Null),
  // true if sql display table failed due to sql query error and fell back to
  // python display table to render the table
  sqlErrorFallback: Boolean,
  // Note this field is not part of the backend DisplayTableOutput, but is output
  // from a separate stage and merged with this object in the frontend
  columnAggregations: ColumnAggregationsOutput,

  // Used exclusively for the Explore cell to specify which type of table output
  // this is. The Explore cell can render multiple types of table at the same time
  // and this field is used to differentiate between them.
  exploreOutputType: Union(DisplayTableExploreOutputType, Null),
});

export const DisplayTableOutput = Intersect(
  DisplayTableOutputOptionalFields,
  DisplayTableOutputRequiredFields,
);

export type DisplayTableOutput = Static<typeof DisplayTableOutput>;

export type DisplayTableRowData = { [columnId: string]: unknown };

export const DISPLAY_TABLE_DEFAULTS = {
  pageSize: 50,
  hideIcons: false,
  hideIndex: false,
  sortDirectionDefault: SortDirection.ASC,
  height: null,
  pinIndexColumns: false,
  showAggregations: false,
} as const;
