import moment from "moment";

import { RelativeDatetimeOffsetValue } from "./datetimeType";

export function computeRelativeDate(
  relativeDate: RelativeDatetimeOffsetValue,
): moment.Moment {
  const duration = moment.duration(
    relativeDate.amount,
    (
      relativeDate.unit + "s"
    ).toLowerCase() as moment.unitOfTime.DurationConstructor,
  );
  return relativeDate.operation === "AGO"
    ? moment().tz(relativeDate.timezone).subtract(duration)
    : moment().tz(relativeDate.timezone).add(duration);
}

export function tryParseRelativeDateOffsetValue(
  serialized: string,
): RelativeDatetimeOffsetValue | null {
  try {
    return RelativeDatetimeOffsetValue.check(JSON.parse(serialized));
  } catch (_err) {
    // noop -- this isn't a relative date
  }
  return null;
}
