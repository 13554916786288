// using tableau10 for now

import { Null, Record, Static, String } from "runtypes";

// TODO(color-palettes): Figure out names for default color palette colors
export const DEFAULT_CHART_COLOR_PALETTE: readonly {
  name: string | null;
  hex: string;
}[] = [
  { hex: "#4C78A8", name: null },
  { hex: "#F58518", name: null },
  { hex: "#E45756", name: null },
  { hex: "#72B7B2", name: null },
  { hex: "#54A24B", name: null },
  { hex: "#EECA3B", name: null },
  { hex: "#B279A2", name: null },
  { hex: "#FF9DA6", name: null },
  { hex: "#9D755D", name: null },
  { hex: "#BAB0AC", name: null },
];

export const MAX_COLORS = 20;
export const MIN_COLORS = 5;

export const HEX_CODE_REGEX = /^#[0-9A-F]{6}$/i;

export const Color = Record({
  hex: String,
  name: String.Or(Null),
});
export type Color = Static<typeof Color>;

// Interval selection style
export const INTERVAL_SELECTION_FILL_COLOR = "#669EFF"; // COBALT5
export const INTERVAL_SELECTION_FILL_OPACITY = 0.07;
export const INTERVAL_SELECTION_STROKE_COLOR = "#669EFF"; // COBALT5
export const INTERVAL_SELECTION_STROKE_OPACITY = 0.4;
