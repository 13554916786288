import { getAllNodesOfType } from "../calcs/calcAstUtils.js";
import { parseCalcExpression } from "../calcs/CalcParser.js";
import { CalcsDefinition } from "../display-table/calcTypes.js";
import { safeCastVariableName } from "../safeCastVariableName.js";

import {
  CellReferencesV2,
  createEmptyParamReferences,
} from "./cellReferencesV2.js";

export const getCalcsCellReferencesV2 = (
  calcs: CalcsDefinition | null | undefined,
): CellReferencesV2 | undefined => {
  if (calcs == null) return undefined;

  const calcExpressions = calcs.calcColumns.map((col) => col.expression);
  const asts = calcExpressions.map((exp) => parseCalcExpression(exp));
  const paramRefNodes = asts.flatMap((ast) =>
    getAllNodesOfType(ast, "parameterReference"),
  );
  const referencedParams = paramRefNodes.map((node) =>
    safeCastVariableName(node.name),
  );

  return {
    newParams: [],
    referencedParams: createEmptyParamReferences(referencedParams),
  };
};
