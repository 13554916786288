import {
  Array,
  Boolean,
  Lazy,
  Literal,
  Record,
  Runtype,
  Static,
  String,
  Union,
} from "runtypes";

import { guardNever } from "../errors";
import { getNormalEnum } from "../runtypeEnums";

export const UnaryColumnPredicateOpLiteral = Union(
  Literal("IS_TRUE"),
  Literal("IS_FALSE"),
  Literal("ALWAYS"),
  Literal("IS_NULL"),
  Literal("NOT_NULL"),
);
export type UnaryColumnPredicateOp = Static<
  typeof UnaryColumnPredicateOpLiteral
>;
export const UnaryColumnPredicateOp = getNormalEnum(
  UnaryColumnPredicateOpLiteral,
);
export const UnaryColumnPredicate = Record({
  op: UnaryColumnPredicateOpLiteral,
});
export type UnaryColumnPredicate = Static<typeof UnaryColumnPredicate>;

export const BinaryColumnPredicateOpLiteral = Union(
  Literal("GT"),
  Literal("GTE"),
  Literal("EQ"),
  Literal("NEQ"),
  Literal("LTE"),
  Literal("LT"),
  Literal("CONTAINS"),
  Literal("NOT_CONTAINS"),
  Literal("DATE_BEFORE"),
  Literal("DATE_EQUAL_OR_BEFORE"),
  Literal("DATE_EQUAL"),
  Literal("DATE_NOT_EQUAL"),
  Literal("DATE_AFTER"),
  Literal("DATE_EQUAL_OR_AFTER"),
);
export type BinaryColumnPredicateOp = Static<
  typeof BinaryColumnPredicateOpLiteral
>;
export const BinaryColumnPredicateOp = getNormalEnum(
  BinaryColumnPredicateOpLiteral,
);

export const ListBinaryColumnPredicateOpLiteral = Union(
  Literal("IS_ONE_OF"),
  Literal("NOT_ONE_OF"),
  Literal("DATE_BETWEEN"),
);
export type ListBinaryColumnPredicateOp = Static<
  typeof ListBinaryColumnPredicateOpLiteral
>;
export const ListBinaryColumnPredicateOp = getNormalEnum(
  ListBinaryColumnPredicateOpLiteral,
);

export const BinaryColumnPredicate = Record({
  op: BinaryColumnPredicateOpLiteral,
  // arg is typically evaluated as a Jinja template, though see InternalBinaryPredicate below
  arg: String,
  // `allowInterpolation` is omitted on the persisted db representation and added when
  // passed them into the kernel. In practice this is used to to determine whether to
  // support jinja templating in filters.
  allowInterpolation: Boolean.optional(),
});
export type BinaryColumnPredicate = Static<typeof BinaryColumnPredicate>;

export const ListBinaryColumnPredicate = Record({
  op: ListBinaryColumnPredicateOpLiteral,
  arg: Array(String),
  // `allowInterpolation` is omitted on the persisted db representation and added when
  // passed them into the kernel. In practice this is used to to determine whether to
  // support jinja templating in filters.
  allowInterpolation: Boolean.optional(),
});
export type ListBinaryColumnPredicate = Static<
  typeof ListBinaryColumnPredicate
>;

export const CompoundColumnPredicateOpLiteral = Union(
  Literal("AND"),
  Literal("OR"),
);
export type CompoundColumnPredicateOp = Static<
  typeof CompoundColumnPredicateOpLiteral
>;
export const CompoundColumnPredicateOp = getNormalEnum(
  CompoundColumnPredicateOpLiteral,
);

export type ColumnPredicateOp =
  | UnaryColumnPredicateOp
  | BinaryColumnPredicateOp
  | ListBinaryColumnPredicateOp
  | CompoundColumnPredicateOp;

// have to manually declare this type since it is recursive
export type CompoundColumnPredicate = {
  op: CompoundColumnPredicateOp;
  args: ColumnPredicate[];
};
export const CompoundColumnPredicate: Runtype<CompoundColumnPredicate> = Lazy(
  () =>
    Record({
      op: CompoundColumnPredicateOpLiteral,
      args: Array(ColumnPredicate),
    }),
);

export const ColumnPredicate = Union(
  UnaryColumnPredicate,
  BinaryColumnPredicate,
  ListBinaryColumnPredicate,
  CompoundColumnPredicate,
);
export type ColumnPredicate =
  | Static<typeof ColumnPredicate>
  | CompoundColumnPredicate;

// Note: When we pass filters into the python kernel they must be converted to
// this internal representation which flags which filters allow Jinja
// interpolation. Filters configured by the app authors allow interpolation but
// additional filters configured by app users do not.

export function toInternalPredicate(
  predicate: ColumnPredicate,
  { allowInterpolation }: { allowInterpolation: boolean },
): ColumnPredicate {
  if (UnaryColumnPredicate.guard(predicate)) {
    return predicate;
  } else if (CompoundColumnPredicate.guard(predicate)) {
    return {
      ...predicate,
      args: predicate.args.map((child) =>
        toInternalPredicate(child, { allowInterpolation }),
      ),
    };
  } else if (
    BinaryColumnPredicate.guard(predicate) ||
    ListBinaryColumnPredicate.guard(predicate)
  ) {
    return {
      ...predicate,
      allowInterpolation,
    };
  } else {
    guardNever(predicate, predicate);
    return predicate;
  }
}
