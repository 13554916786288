import {
  Literal,
  Number,
  Optional,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import { getNormalEnum } from "./runtypeEnums";

export const DATE_FORMAT_WITH_TIME = "YYYY-MM-DD hh:mm A";
export const DATE_FORMAT_NO_TIME = "YYYY-MM-DD";

export const DateAndTime = Record({
  isoDateString: String,
  timezoneName: String,
});
export const DateOnly = Record({
  dateString: String,
});

export const RelativeDateUnitLiteral = Union(
  Literal("DAY"),
  Literal("WEEK"),
  Literal("MONTH"),
  Literal("YEAR"),
);

export type RelativeDateUnit = Static<typeof RelativeDateUnitLiteral>;
export const RelativeDateUnit = getNormalEnum(RelativeDateUnitLiteral);

export const RelativeDateOperationLiteral = Union(
  Literal("AGO"),
  Literal("FROM_NOW"),
);

export type RelativeDateOperation = Static<typeof RelativeDateOperationLiteral>;
export const RelativeDateOperation = getNormalEnum(
  RelativeDateOperationLiteral,
);

export const RelativeDatetimeOffsetValue = Record({
  displayName: Optional(String),
  unit: RelativeDateUnitLiteral,
  amount: Number,
  operation: RelativeDateOperationLiteral,
  timezone: String,
});

export type RelativeDatetimeOffsetValue = Static<
  typeof RelativeDatetimeOffsetValue
>;

export const DatetimeValue = Union(
  DateAndTime,
  DateOnly,
  RelativeDatetimeOffsetValue,
);

export type DatetimeValue = Static<typeof DatetimeValue>;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const StringDate = String.withBrand("stringDate").withConstraint(
  (s) => !isNaN(new Date(s).getTime()), // Ensure the string is Date parsable.
);
export type StringDate = Static<typeof StringDate>;
