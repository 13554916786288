/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import { groupBy } from "lodash";
import {
  Boolean,
  Dictionary,
  Null,
  Number,
  Partial,
  Array as RArray,
  Record,
  Static,
  String,
  Tuple,
  Union,
  Unknown,
} from "runtypes";

import { VariableName } from "../typeBrands.js";

export function mergeParamReferences(
  ...refs: Array<readonly ParamReference[]>
): ParamReference[] {
  const groupedByParam = groupBy(refs.flat(), (ref) => ref.param);
  return Object.values(groupedByParam).map((group) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { param } = group[0]!;
    return {
      param,
      locations: group.flatMap(({ locations }) => locations),
    };
  });
}

export const SourcePosition = Record({
  line: Number,
  column: Number,
});
export type SourcePosition = Static<typeof SourcePosition>;

export const SourceRange = Tuple(SourcePosition, SourcePosition);
export type SourceRange = Static<typeof SourceRange>;

export const SourceLocation = SourcePosition.And(
  Partial({
    length: Union(Number, Null),
  }),
);
export type SourceLocation = Static<typeof SourceLocation>;

export const ParamReference = Record({
  param: VariableName,
  locations: RArray(SourceLocation).asReadonly(),
});
export type ParamReference = Static<typeof ParamReference>;

export const CellReferencesV1 = Record({
  referencedParams: RArray(VariableName).asReadonly(),
  newParams: RArray(VariableName).asReadonly(),
});

export type CellReferencesV1 = Static<typeof CellReferencesV1>;

export const FunctionDefinition = Record({
  name: String,
  src: String,
});
export type FunctionDefinition = Static<typeof FunctionDefinition>;

export const PythonCellReferencesV1 = CellReferencesV1.extend({
  imports: RArray(VariableName).asReadonly(),
  importStrings: RArray(String).asReadonly(),
  functions: RArray(FunctionDefinition).asReadonly(),
});
export type PythonCellReferencesV1 = Static<typeof PythonCellReferencesV1>;

export const SqlCellReferencesV1 = CellReferencesV1.extend({
  containsBlocks: Boolean,
});
export type SqlCellReferencesV1 = Static<typeof SqlCellReferencesV1>;

export const CellReferencesParseError = Record({
  error: String,
});

export type CellReferencesParseError = Static<typeof CellReferencesParseError>;

export const ParsedPythonCellReferencesV1 = Union(
  PythonCellReferencesV1,
  CellReferencesParseError,
);
export type ParsedPythonCellReferencesV1 = Static<
  typeof ParsedPythonCellReferencesV1
>;

export const FormatSourceError = Record({
  error: String,
});
export type FormatSourceError = Static<typeof FormatSourceError>;

export const FormatSourceResponse = Union(String, FormatSourceError);
export type FormatSourceResponse = Static<typeof FormatSourceResponse>;

export const SyncSemanticLayerError = Record({
  error: String,
  code: Number,
});
export type SyncSemanticLayerError = Static<typeof FormatSourceError>;

export const SyncSemanticLayerResponse = Union(
  Record({
    semantic_projects: RArray(Unknown),
    skipped: Dictionary(
      Dictionary(RArray(Record({ name: String, reason: String }))),
    ),
  }),
  SyncSemanticLayerError,
);
export type SyncSemanticLayerResponse = Static<
  typeof SyncSemanticLayerResponse
>;
