/**
 * Given a discriminated union and a branch of that discriminate,
 * extracts the full branch from the union.
 *
 * Given a union like so:
 * ```ts
 * type U = { type: "A"; x: string } | { type: "B"; y: number };
 * ```
 *
 * Using this type like so:
 * ```ts
 * ExtractBranchFromUnion<U, "type", "A">
 * ```
 *
 * Will give you the branch:
 * ```ts
 * { type: "A"; x: string }
 * ```
 */
export type ExtractBranchFromUnion<
  UNION,
  DISCRIMINANT extends keyof UNION,
  BRANCH extends UNION[DISCRIMINANT],
> = UNION extends Record<DISCRIMINANT, BRANCH> ? UNION : never;

// Using this type in our types below gives us better ergonomics,
// see https://stackoverflow.com/q/71053032/3124288
type DiscriminantLiteral = PropertyKey | boolean;

/**
 * Given a discriminated union and a branch, provides a typeguard
 */
export function narrow<
  UNION,
  DISCRIMINANT extends keyof UNION,
  BRANCH extends UNION[DISCRIMINANT] & DiscriminantLiteral,
>(
  discriminate: DISCRIMINANT,
  branch: BRANCH,
): (
  item: UNION,
) => item is ExtractBranchFromUnion<UNION, DISCRIMINANT, BRANCH> {
  return (item): item is ExtractBranchFromUnion<UNION, DISCRIMINANT, BRANCH> =>
    item[discriminate] === branch;
}

/**
 * Filters an array of discrimanated unions to a specific branch
 * of the union.
 */
export function filterUsingDiscriminate<
  UNION,
  DISCRIMINANT extends keyof UNION,
  BRANCH extends UNION[DISCRIMINANT] & DiscriminantLiteral,
>(
  items: readonly UNION[],
  discriminate: DISCRIMINANT,
  branch: BRANCH,
): ExtractBranchFromUnion<UNION, DISCRIMINANT, BRANCH>[] {
  return items.filter(narrow(discriminate, branch));
}

/**
 * Given an array of discrimanated unions, creates an object
 * with a key for every branch of the discrimanate whose
 * values are the corresponding items.
 */
export function groupByDiscriminate<UNION, DISCRIMINANT extends keyof UNION>(
  items: readonly UNION[],
  discriminate: DISCRIMINANT,
): {
  [BRANCH in UNION[DISCRIMINANT] extends string
    ? UNION[DISCRIMINANT]
    : never]?: ExtractBranchFromUnion<UNION, DISCRIMINANT, BRANCH>[];
} {
  return items.reduce<{
    [BRANCH in UNION[DISCRIMINANT] extends string
      ? UNION[DISCRIMINANT]
      : never]?: ExtractBranchFromUnion<UNION, DISCRIMINANT, BRANCH>[];
  }>((acc, item) => {
    const branch = item[discriminate] as keyof typeof acc;
    acc[branch] ??= [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    acc[branch]?.push(item as any);
    return acc;
  }, {});
}
