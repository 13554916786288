import {
  Boolean,
  Literal,
  Number,
  Optional,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import { DatetimeColumnDisplayFormatTypeLiteral } from "../datetimeDisplayFormatTypes.js";
import { NumberDisplayFormatTypeLiteral } from "../numberDisplayFormatTypes";
import { getNormalEnum } from "../runtypeEnums";

import { DisplayTableColumnOutputType } from "./outputTypes";

//#region boolean columns
export const BooleanColumnDisplayFormatType = Union(Literal("PLAIN"));
export const BooleanColumnDisplayFormat = Record({
  columnType: Literal(DisplayTableColumnOutputType.BOOLEAN),
  format: BooleanColumnDisplayFormatType,
});
export type BooleanColumnDisplayFormat = Static<
  typeof BooleanColumnDisplayFormat
>;
//#endregion

//#region datetime columns
const DateColumnDisplayFormatTypeLiteral = Union(
  Literal("PLAIN"),
  /** A localized date representation with no time info */
  Literal("DATE"),
  /** A longer localized date representation with no time info */
  Literal("DATE_LONG"),
);
export type DateColumnDisplayFormatType = Static<
  typeof DateColumnDisplayFormatTypeLiteral
>;
export const DateColumnDisplayFormatType = getNormalEnum(
  DateColumnDisplayFormatTypeLiteral,
);

export const DateColumnDisplayFormat = Record({
  columnType: Literal(DisplayTableColumnOutputType.DATE),
  format: DateColumnDisplayFormatTypeLiteral,
});
export type DateColumnDisplayFormat = Static<typeof DateColumnDisplayFormat>;
//#endregion

//#region datetime columns

const DatetimeColumnDisplayFormatTimezoneLiteral = Union(
  /** Display the date/time as is, ignoring the presence of any offset */
  Literal("IGNORE"),

  /**
   * Convert the underlying datetime into the current viewer's browser's timezone
   * before displaying the date/time. If the underlying datetime has no offset,
   * it will be assumed to be in UTC prior to conversion.
   */
  Literal("LOCAL"),
);
export type DatetimeColumnDisplayFormatTimezone = Static<
  typeof DatetimeColumnDisplayFormatTimezoneLiteral
>;
export const DatetimeColumnDisplayFormatTimezone = getNormalEnum(
  DatetimeColumnDisplayFormatTimezoneLiteral,
);

export const DatetimeColumnDisplayFormat = Record({
  columnType: Literal(DisplayTableColumnOutputType.DATETIME),
  format: DatetimeColumnDisplayFormatTypeLiteral,
  // Used by all formats except `PLAIN`
  timezone: Optional(DatetimeColumnDisplayFormatTimezoneLiteral),
});
export type DatetimeColumnDisplayFormat = Static<
  typeof DatetimeColumnDisplayFormat
>;
//#endregion

//#region number columns
export type NumberColumnDisplayFormatType = Static<
  typeof NumberDisplayFormatTypeLiteral
>;
export const NumberColumnDisplayFormatType = getNormalEnum(
  NumberDisplayFormatTypeLiteral,
);

export const NumberColumnDisplayFormat = Record({
  columnType: Literal(DisplayTableColumnOutputType.NUMBER),
  format: NumberDisplayFormatTypeLiteral,
  // Used by all formats except `PLAIN`
  numDecimalDigits: Number,
  // Used by `CURRENCY`, `CURRENCY_LONG`, and `FINANCIAL`
  currency: String,
  // when numbers are big (1,000,000), abbreviate (1M)
  abbreviateLargeNumbers: Optional(Boolean),
  // what string to use for NaN/null/empty
  nanFormat: Optional(String),
  // show separators (usually commas) between 000s
  showSeparators: Optional(Boolean),
});
export type NumberColumnDisplayFormat = Static<
  typeof NumberColumnDisplayFormat
>;
//#endregion

//#region string columns
export const StringColumnDisplayFormatTypeLiteral = Union(
  Literal("PLAIN"),
  Literal("URL"),
);
export type StringColumnDisplayFormatType = Static<
  typeof StringColumnDisplayFormatTypeLiteral
>;
export const StringColumnDisplayFormatType = getNormalEnum(
  StringColumnDisplayFormatTypeLiteral,
);

export const StringColumnDisplayFormat = Record({
  columnType: Literal(DisplayTableColumnOutputType.STRING),
  format: StringColumnDisplayFormatTypeLiteral,
});
export type StringColumnDisplayFormat = Static<
  typeof StringColumnDisplayFormat
>;
//#endregion

//#region unknown columns
export const UnknownColumnDisplayFormatType = Union(Literal("PLAIN"));
export const UnknownColumnDisplayFormat = Record({
  columnType: Literal(DisplayTableColumnOutputType.UNKNOWN),
  format: UnknownColumnDisplayFormatType,
});
export type UnknownColumnDisplayFormat = Static<
  typeof UnknownColumnDisplayFormat
>;
//#endregion

export const ColumnDisplayFormat = Union(
  BooleanColumnDisplayFormat,
  DateColumnDisplayFormat,
  DatetimeColumnDisplayFormat,
  NumberColumnDisplayFormat,
  StringColumnDisplayFormat,
  UnknownColumnDisplayFormat,
);
export type ColumnDisplayFormat = Static<typeof ColumnDisplayFormat>;
