/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import {
  Boolean,
  Array as RArray,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import { notEmpty } from "../notEmpty";
import { VariableName } from "../typeBrands";

import {
  CellReferencesParseError,
  CellReferencesV1,
  FunctionDefinition,
  ParamReference,
  SourceLocation,
  mergeParamReferences,
} from "./cellReferencesV1";

export function createEmptyParamReferences(
  params: readonly VariableName[],
): ParamReference[] {
  return params.map((param) => ({
    param,
    locations: [],
  }));
}

export function upgradeCellReferencesV1<T extends CellReferencesV1>(
  references: T,
): Omit<T, keyof CellReferencesV1> & CellReferencesV2 {
  return {
    ...references,
    newParams: createEmptyParamReferences(references.newParams),
    referencedParams: createEmptyParamReferences(references.referencedParams),
  };
}

export function downgradeCellReferencesV2<T extends CellReferencesV2>(
  references: T,
): Omit<T, keyof CellReferencesV2> & CellReferencesV1 {
  return {
    ...references,
    newParams: references.newParams.map((ref) => ref.param),
    referencedParams: references.referencedParams.map((ref) => ref.param),
  };
}

export function mergeCellReferencesV2(
  ...refs: Array<CellReferencesV2 | null | undefined>
): CellReferencesV2 {
  const nonNullRefs = refs.filter(notEmpty);
  return {
    newParams: mergeParamReferences(
      ...nonNullRefs.map(({ newParams }) => newParams),
    ),
    referencedParams: mergeParamReferences(
      ...nonNullRefs.map(({ referencedParams }) => referencedParams),
    ),
  };
}

export const ErrorDefinition = Record({
  errorType: String,
  description: String,
  location: SourceLocation,
});
export type ErrorDefinition = Static<typeof ErrorDefinition>;

export const CellReferencesV2 = Record({
  referencedParams: RArray(ParamReference).asReadonly(),
  newParams: RArray(ParamReference).asReadonly(),
});

export type CellReferencesV2 = Static<typeof CellReferencesV2>;

export const PythonCellReferencesV2 = CellReferencesV2.extend({
  imports: RArray(VariableName).asReadonly(),
  importStrings: RArray(String).asReadonly(),
  functions: RArray(FunctionDefinition).asReadonly(),
  errors: RArray(ErrorDefinition).asReadonly().optional(),
});
export type PythonCellReferencesV2 = Static<typeof PythonCellReferencesV2>;

export const RCellReferences = PythonCellReferencesV2;
export type RCellReferences = Static<typeof RCellReferences>;

export const SqlCellReferencesV2 = CellReferencesV2.extend({
  containsBlocks: Boolean,
});
export type SqlCellReferencesV2 = Static<typeof SqlCellReferencesV2>;

export const ParsedPythonCellReferencesV2 = Union(
  PythonCellReferencesV2,
  CellReferencesParseError,
);
export type ParsedPythonCellReferencesV2 = Static<
  typeof ParsedPythonCellReferencesV2
>;

export const ParsedRCellReferences = Union(
  RCellReferences,
  CellReferencesParseError,
);
export type ParsedRCellReferences = Static<typeof ParsedRCellReferences>;
