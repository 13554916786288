import {
  ComputedCellReferencesV3,
  ComputedSqlCellReferencesV3,
} from "../code/cellReferencesV3.js";
import {
  BaseGraphCellV3,
  CellGraphNodeV3,
  ComputedCellReferenceMapV3,
  GraphNodeV3,
  GraphV3,
  traverseGraphV3FromNodesBFS,
} from "../code/projectGraphV3.js";
import { CellType } from "../enums.js";
import { CellId } from "../idTypeBrands.js";
import { notEmpty } from "../notEmpty.js";
import { typedObjectValues } from "../utils/typedObjects.js";

export const UNSAFE_TO_SKIP_CELL_TYPES = new Set<CellType>([
  CellType.CODE,
  CellType.WRITEBACK,
  CellType.COMPONENT_IMPORT,
]);

const isPrunableCell = ({
  cellReferences,
  node,
}: {
  node: GraphNodeV3<BaseGraphCellV3>;
  cellReferences: ComputedCellReferencesV3[] | undefined;
}): boolean => {
  if (node.type === "cell" && node.cell.cellType) {
    // If the cell is not attached to the app and cell type is safe to skip
    if (!UNSAFE_TO_SKIP_CELL_TYPES.has(node.cell.cellType)) {
      if (node.cell.cellType === "SQL") {
        // AND (for SQL) it's a select only statement
        if (cellReferences != null) {
          const sqlRefs = cellReferences.filter(
            (ref): ref is ComputedSqlCellReferencesV3 =>
              ComputedSqlCellReferencesV3.guard(ref),
          );
          return (sqlRefs.length === 1 && sqlRefs[0]?.onlySelects) ?? false;
        } else {
          return false;
        }
      }
      return true;
    }
  }

  return false;
};

export const getCellIdsToSkipForAppRuns = ({
  cellReferences,
  cellsInApp,
  graphV3,
}: {
  graphV3: GraphV3<BaseGraphCellV3>;
  cellsInApp: BaseGraphCellV3[];
  cellReferences: ComputedCellReferenceMapV3<BaseGraphCellV3>;
}): Set<CellId> => {
  const cellIdsInApp = new Set<CellId>([...cellsInApp.map((c) => c.id)]);

  const allCells = typedObjectValues(graphV3).filter(
    (n): n is CellGraphNodeV3<BaseGraphCellV3> => n.type === "cell",
  );

  const allCellsIsPrunable = allCells
    .map((c) => {
      const node = graphV3[c.cellId];
      if (node == null) {
        return;
      }
      const isPrunable = isPrunableCell({
        node,
        cellReferences: cellReferences[c.cellId]?.references,
      });
      return {
        cellId: c.cellId,
        isPrunable,
      };
    })
    .filter(notEmpty);
  const notSafeToPruneCells = allCellsIsPrunable
    .filter((c) => !c.isPrunable)
    .map((c) => c.cellId);

  const baseCellsToRun = new Set<CellId>([
    ...notSafeToPruneCells,
    ...cellIdsInApp,
  ]);

  // Traverse upwards from base cells to get the full set of cells that are needed to run
  const allCellsToRun = traverseGraphV3FromNodesBFS({
    graph: graphV3,
    nodeIds: Array.from(baseCellsToRun),
    direction: "upstream",
    includeOriginals: true,
  });
  const allCellIdsToRun = new Set<CellId>([
    ...allCellsToRun
      .filter((n): n is CellGraphNodeV3<BaseGraphCellV3> => n.type === "cell")
      .map((c) => c.cellId),
  ]);
  // Inverse the cells to run to get the set of cells we can skip and return that.
  const cellsToSkip = new Set<CellId>([
    ...allCells
      .filter((c) => !allCellIdsToRun.has(c.cellId))
      .map((c) => c.cellId),
  ]);
  return cellsToSkip;
};
