import { Literal, Static, Union } from "runtypes";

import { getNormalEnum } from "./runtypeEnums.js";

export const DatetimeColumnDisplayFormatTypeLiteral = Union(
  Literal("PLAIN"),
  /** A localized date representation with no time info */
  Literal("DATE"),
  /** ISO 8601 representation of date with no time info (YYYY-MM-DD) */
  Literal("DATE_ISO8601"),
  /** A longer localized date representation with no time info */
  Literal("DATE_LONG"),
  /** A localized time representation with no date info */
  Literal("TIME"),
  /** A localized date and time representation */
  Literal("DATETIME"),
);
export type DatetimeColumnDisplayFormatType = Static<
  typeof DatetimeColumnDisplayFormatTypeLiteral
>;
export const DatetimeColumnDisplayFormatType = getNormalEnum(
  DatetimeColumnDisplayFormatTypeLiteral,
);
